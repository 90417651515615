


















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import userx from '@/store/modules/userx';
import { EditState } from '@/store/models.def';
import {
  PasswordStrength,
  passwordStrengthRequirement,
} from '../inputs/password.def';
import PasswordInput from '../inputs/PasswordInput.vue';
import { OAuthSigninMethod, User } from '@/store/firestore';
import EmailInput from '../inputs/EmailInput.vue';
import SocialMediaReloginButton from './SocialMediaReloginButton.vue';

@Component({
  components: {
    PasswordInput,
    EmailInput,
    SocialMediaReloginButton,
  },
})
export default class ChangeEmailManager extends Vue {
  @Prop() public user!: User;


  public get noPassword() {
    return this.user.noPassword;
  }

  public get reloginMethods() {
    const methods: OAuthSigninMethod[] = [];
    for (const provider of this.user.providerData) {
      if (provider.providerId !== 'password') {
        methods.push(
          provider.providerId.replace('.com', '') as OAuthSigninMethod,
        );
      }
    }
    return methods;
  }

  public password = '';
  public validatePasswordNow = false;
  public passwordStrength = PasswordStrength.Medium;
  public reloginState: EditState = 'active';

  public get reloginLabel() {
    const labels = {
      active: 'Relogin',
      sending: '',
      done: 'Succeed',
    };
    return labels[this.reloginState];
  }

  public email = this.user?.email || '';
  public validateEmailNow = false;
  public changeEmailErrorCode: string = '';
  public changeEmailError: string = '';
  public changeEmailSuccess: string = '';
  public changeEmailState: EditState = 'active';

  public get changeEmailLabel() {
    const labels = {
      active: 'Update Email',
      sending: 'Updating...',
      done: 'Email Updated',
    };
    return labels[this.changeEmailState];
  }

  public async submit() {
    return this.changeEmail();
  }

  public async changeEmail() {
    if (this.changeEmailState !== 'active') {
      return;
    }
    if (this.email === this.user.email) {
      this.changeEmailError = 'This is your current email address.';
      return;
    }
    this.validateEmailNow = true;
    if (
      this.email !== null
    ) {
      this.changeEmailState = 'sending';
      const email = this.email;
      try {
        await userx.updateUserEmail(email);
        this.changeEmailState = 'active';
        this.changeEmailError = '';
        this.validateEmailNow = false;
        this.$emit('sent');

      } catch (error) {
        this.changeEmailErrorCode = (error as any).code;
        this.changeEmailError = (error as any).message;
        this.changeEmailState = 'active';
      }
    }
  }


  public async relogin() {
    return this.reloginPassword();
  }

  public async reloginPassword() {
    if (this.reloginState !== 'active') {
      return;
    }
    this.validatePasswordNow = true;
    if (
      this.password !== null
    ) {
      this.reloginState = 'sending';
      const email = this.user.email;
      const password = this.password;
      try {
        await userx.signIn({ email, password });
        this.reloginState = 'active';
        this.validatePasswordNow = false;
        this.reloginState = 'done';
        this.changeEmailErrorCode = '';
        this.changeEmailError = '';
      } catch (error) {
        this.showError((error as any).message);
        this.reloginState = 'active';
      }
    }
  }
  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
