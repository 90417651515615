















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import userx from '@/store/modules/userx';
import { User } from '@/store/firestore';
import { EditState } from '@/store/models.def';
import SocialMediaLinkButton from './SocialMediaLinkButton.vue';
import { authorizePoq } from '@/components/gacha/poq//poqFunctions';
import CrazyGamesLinkButton from './CrazyGamesLinkButton.vue';
import globalx from '@/store/modules/globalx';

@Component({
  components: {
    SocialMediaLinkButton,
    CrazyGamesLinkButton,
  },
})
export default class SocialMediaPanel extends Vue {
  @Prop() public user!: User;

  public baseUrl = process.env.BASE_URL;
  public get poqLabel() {
    return userx.userDoc.poq ? 'Relink with poq.gg' : 'Link with poq.gg';
  }
  public get poqIndentity() {
    return userx.userDoc.poq;
  }

  public linkPoq() {
    authorizePoq();
  }

  public get showCrazyGamesButtons() {
    return globalx.showCrazyGamesButtons;
  }
}
