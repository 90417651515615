











































































import { ItemCode } from '@/game/infos/itemInfos';
import { levelRequirements } from '@/game/infos/levelInfos';
import continueToUrl from '@/helpers/continueToUrl';
import { Global } from '@/store/globalz';
import { EditState } from '@/store/models.def';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import MutableInputContainer from '../inputs/MutableInputContainer.vue';
import PasswordManager from './PasswordManager.vue';
import ChangeEmailManager from './ChangeEmailManager.vue';
import SocialMediaPanel from './SocialMediaPanel.vue';

@Component({
  components: {
    PasswordManager,
    SocialMediaPanel,
    MutableInputContainer,
    ChangeEmailManager,
  },
})
export default class UserPanel extends Vue {
  public get user() {
    return userx.user;
  }
  public get email() {
    return userx.user?.email || '';
  }
  public get emailVerified() {
    return userx.user?.emailVerified;
  }
  public get noPassword() {
    return this.user?.noPassword || false;
  }
  public get providerData() {
    return this.user?.providerData || [];
  }
  public get uid() {
    return userx.userDoc.uid;
  }
  public get lvl() {
    return userx.userDoc.lvl;
  }
  public get money() {
    return userx.inventory.money;
  }
  public get gem() {
    return userx.inventory.availables[ItemCode.Gem];
  }
  public get xp() {
    let expLeft = userx.userDoc.exp;
    let expReq = levelRequirements[0];
    for (const req of levelRequirements) {
      if (expLeft < req) {
        expReq = req;
        break;
      }
      expLeft -= req;
    }
    return `${expLeft} / ${expReq}`;
  }

  public get userName() {
    return userx.userDoc.name;
  }

  public displayNameInput: string = '';
  public displayNameEditState: EditState = 'done';
  public validateDisplayNameNow = false;

  public showChangeEmail = false;

  @Watch('displayNameEditState')
  public onPathEditStateChanged(val: EditState, oldVal: EditState) {
    if (val === 'active' && oldVal !== 'sending') {
      this.displayNameInput = this.userName || '';
    }
  }
  public get validateDisplayName(): boolean | null {
    if (!this.validateDisplayNameNow) {
      return null;
    }
    return this.displayNameInput.length >= 4;
  }

  public async submitDisplayName() {
    this.validateDisplayNameNow = true;
    this.displayNameInput = this.displayNameInput.replace('\\', '/');
    if (!this.validateDisplayName) {
      await this.$nextTick();
      this.displayNameEditState = 'active';
      return;
    }
    try {
      this.displayNameEditState = 'sending';
      await userx.updateDisplayName(this.displayNameInput);
      this.validateDisplayNameNow = false;
      this.displayNameEditState = 'done';
    } catch (error) {
      this.displayNameEditState = 'active';
      // show error modal
      this.$root.$emit('error', error);
    }
  }

  public get pageReady() {
    if (userx.loginStatus === 'loading') {
      return 'auth';
    } else if (userx.loginStatus === 'logged in') {
      if (!globalx.userDocsReady) {
        return 'loading';
      }
      return 'done';
    } else if (userx.loginStatus === 'logged out') {
      return 'redirect';
    }
  }
  @Watch('pageReady', { immediate: true })
  public pageReadyChanged(newVal: string) {
    if (newVal === 'auth') {
      this.$root.$emit('loading', 'Authenticating...');
    } else if (newVal === 'loading') {
      this.$root.$emit('loading', 'Loading  User  Data...');
    } else {
      this.$root.$emit('loading', '');
    }
    if (newVal === 'redirect') {
      this.$router.push('/login');
    }
  }

  public onShop() {
    Global.shopModal.show();
  }
  public onBack() {
    this.$router.go(-1);
  }

  public async onVerify() {
    try {
      await userx.sendEmailVerification();
      this.$bvModal.msgBoxOk(
        'Verification email sent, please check your inbox or junk mail.',
        {
          titleHtml: `<i class="fa fa-check-circle"></i> Success`,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          modalClass: 'funny-modal',
        },
      );
    } catch (error) {
      this.showError((error as any).message);
    }
  }
  public async onChangeEmailSent() {
    this.$bvModal.msgBoxOk(
      'Verification email sent to your new address, please check your inbox or junk mail. Your account email will be updated upon verification.',
      {
        titleHtml: `<i class="fa fa-check-circle"></i> Success`,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        modalClass: 'funny-modal',
      },
    );
    this.showChangeEmail = false;
  }
  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
