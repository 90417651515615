













import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import userx from '@/store/modules/userx';
import { OAuthSigninMethod, User } from '@/store/firestore';
import { EditState } from '@/store/models.def';

@Component({
  components: {},
})
export default class SocialMediaReloginButton extends Vue {
  @Prop({ default: 'google' }) public method!: OAuthSigninMethod;
  public state: EditState = 'active';
  public get providerName() {
    return this.method.slice(0, 1).toUpperCase() + this.method.slice(1);
  }
  public get iconClass() {
    return `fab fa-${this.method}`;
  }
  public get variant() {
    return `brand-${this.method}`;
  }
  public get title() {
    return `Relogin with ${this.providerName}`;
  }
  // public get label() {
  //   const labels = {
  //     active: ``,
  //     sending: ``,
  //     done: ``,
  //   };
  //   return labels[this.state];
  // }

  public async relogin() {
    this.state = 'sending';
    try {
      await userx.signInWithOAuth(this.method);
      this.$emit('done');
    } catch (error) {
      console.log(error);
      this.state = 'active';
    }
  }
}
