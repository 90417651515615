





























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import userx from '@/store/modules/userx';
import { EditState } from '@/store/models.def';
import {
  PasswordStrength,
  passwordStrengthRequirement,
} from '../inputs/password.def';
import PasswordInput from '../inputs/PasswordInput.vue';
import { OAuthSigninMethod, User } from '@/store/firestore';
import EmailInput from '../inputs/EmailInput.vue';
import SocialMediaReloginButton from './SocialMediaReloginButton.vue';

@Component({
  components: {
    PasswordInput,
    EmailInput,
    SocialMediaReloginButton,
  },
})
export default class PasswordManager extends Vue {
  @Prop() public user!: User;

  public get noPassword() {
    return this.user.noPassword;
  }

  public get title() {
    return this.noPassword ? 'Create Password' : 'Reset Password';
  }
  public get emailLabel() {
    return 'Email';
  }
  public get oldPasswordLabel() {
    return 'Old Password';
  }
  public get newPasswordLabel() {
    return this.noPassword ? 'Password' : 'New Password';
  }
  public get repeatPasswordLabel() {
    return 'Repeat Password';
  }

  public get reloginMethods() {
    const methods: OAuthSigninMethod[] = [];
    for (const provider of this.user.providerData) {
      if (provider.providerId !== 'password') {
        methods.push(
          provider.providerId.replace('.com', '') as OAuthSigninMethod,
        );
      }
    }
    return methods;
  }

  public email = this.user?.email || '';
  public password = '';
  public oldPassword = '';
  public repeatPassword = '';
  public validatePasswordNow = false;
  public passwordStrength = PasswordStrength.Medium;
  public savePasswordErrorCode: string = '';
  public savePasswordError: string = '';
  public savePasswordSuccess: string = '';
  public savePasswordState: EditState = 'active';

  public get savePasswordLabel() {
    const labels = this.noPassword
      ? {
          active: 'Create Password',
          sending: 'Creating...',
          done: 'Password Created',
        }
      : {
          active: 'Reset Password',
          sending: 'Resetting...',
          done: 'Password Reset',
        };
    return labels[this.savePasswordState];
  }

  get passwordNotSame() {
    if (!this.validatePasswordNow) {
      return false;
    }
    return this.password !== this.repeatPassword;
  }
  get passwordRequirement() {
    return this.passwordNotSame
      ? 'Two passwords do not match.'
      : passwordStrengthRequirement[this.passwordStrength];
  }

  public async submit() {
    if (this.noPassword) {
      return this.createPasswordAuthentication();
    }
    return this.updatePassword();
  }

  public async updatePassword() {
    if (this.savePasswordState !== 'active') {
      return;
    }
    this.validatePasswordNow = true;
    if (
      this.oldPassword !== null &&
      this.password !== null &&
      this.repeatPassword !== null &&
      this.passwordNotSame === false
    ) {
      this.savePasswordState = 'sending';
      const oldPassword = this.oldPassword;
      const newPassword = this.password;
      try {
        await userx.signInAndResetPassword({
          oldPassword,
          newPassword,
        });
        this.savePasswordState = 'active';
        this.savePasswordSuccess = 'Success';
        this.savePasswordError = '';
        this.validatePasswordNow = false;
        this.oldPassword = this.password = this.repeatPassword = '';
      } catch (error) {
        this.savePasswordErrorCode = (error as any).code;
        this.savePasswordError = (error as any).message;
        this.savePasswordState = 'active';
      }
    }
  }

  public async createPasswordAuthentication() {
    if (this.savePasswordState !== 'active') {
      return;
    }
    this.validatePasswordNow = true;
    if (
      this.email !== null &&
      this.password !== null &&
      this.repeatPassword !== null &&
      this.passwordNotSame === false
    ) {
      this.savePasswordState = 'sending';
      const email = this.email;
      const password = this.password;
      try {
        await userx.linkUserWithEmailAndPassword({
          email,
          password,
        });
        this.savePasswordState = 'active';
        this.savePasswordSuccess = 'Success';
        this.savePasswordError = '';
        this.validatePasswordNow = false;
        this.oldPassword = this.password = this.repeatPassword = '';
      } catch (error) {
        this.savePasswordErrorCode = (error as any).code;
        this.savePasswordError = (error as any).message;
        this.savePasswordState = 'active';
      }
    }
  }
}
