














import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import userx from '@/store/modules/userx';
import { OAuthSigninMethod, User } from '@/store/firestore';
import { EditState } from '@/store/models.def';

@Component({
  components: {},
})
export default class SocialMediaLinkButton extends Vue {
  @Prop() public user!: User;
  @Prop({ default: 'google' }) public method!: OAuthSigninMethod;
  public state: EditState = 'active';
  public get hasProvider() {
    return (
      this.user.providerData.findIndex(
        (p) => p.providerId === `${this.method}.com`,
      ) !== -1
    );
  }
  public get providerName() {
    return this.method.slice(0, 1).toUpperCase() + this.method.slice(1);
  }
  public get iconClass() {
    return `fab fa-${this.method} px-2`;
  }
  public get variant() {
    return `brand-${this.method}`;
  }
  public get title() {
    return this.hasProvider
      ? `Unlink with ${this.providerName}`
      : `Link with ${this.providerName}`;
  }
  public get label() {
    const labels = this.hasProvider
      ? {
          active: `Unlink with ${this.providerName}`,
          sending: `Unlinking...`,
          done: `Unlinked with ${this.providerName}`,
        }
      : {
          active: `Link with ${this.providerName}`,
          sending: `Linking...`,
          done: `Linked with ${this.providerName}`,
        };
    return labels[this.state];
  }

  public async linkUnlink() {
    if (this.hasProvider) {
      return this.unlink();
    }
    return this.link();
  }

  public async link() {
    this.state = 'sending';
    try {
      const result = await userx.linkUserWithOAuth(this.method);
    } catch (error) {
      this.$root.$emit('error', error);
    }
    this.state = 'active';
  }
  public async unlink() {
    const value = await this.$bvModal.msgBoxConfirm('Are you sure?', {
      title: `Unlink with ${this.providerName}`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      cancelVariant: 'link btn-link-warning',
      modalClass: 'funny-modal',
      okTitle: 'Confirm',
      cancelTitle: 'Cancel',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true,
    });
    if (!value) {
      return;
    }
    this.state = 'sending';
    const result = await userx.unlinkUserWithOAuth(this.method);
    this.state = 'active';
  }
}
